/* Global Styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header Styles */
.hero {
  position: relative;
  height: 100vh;
  background: url('./poster.jpeg') center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.hero-content {
  z-index: 1;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #fff;
}

.hero p {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 30px;
}

/* Button Styles */
.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

/* About Section Styles */
.about-section {
  padding: 80px 0;
  background-color: #fff;
}

.about-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

/* Features Section Styles */
.features-section {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.features-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.feature {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.feature h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.feature img {
  max-width: 100%; /* Ensures image doesn't overflow horizontally */
  object-fit: cover; /* Ensures the image covers the entire container */
  border-radius: 10px;
  margin: 0 auto; /* Center the image horizontally */
  display: block; /* Ensures margin:auto works properly */
  margin-bottom: 20px;
}

/* Call to Action Section Styles */
.cta-section {
  padding: 80px 0;
  text-align: center;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

/* Footer Section Styles */
.footer {
  padding: 20px 0;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.footer p {
  font-size: 1rem;
}

.subscribe {
  font-family: "Satisfy";
  font-size: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 2.6rem;
  text-align: center;
  line-height: 1;
  color: #303235;
  animation: neon 0.08s ease-in-out infinite alternate;
}