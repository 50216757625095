.tracker-app {
    display: grid;
    place-items: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    padding: 20px;
    box-sizing: border-box;
}

.tracker-title {
    text-align: center;
    margin-bottom: 20px; /* Adjust the margin bottom to reduce the gap */
}

.tracker-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    max-width: 600px;
    box-sizing: border-box;
}

.tracker-button {
    padding: 15px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s ease;
}

.tracker-button:hover {
    background-color: #0056b3;
}
