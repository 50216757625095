.desktop-parent{
    background-image: url("../public/wall.jpeg");
    /* background-image: url("../public/gifbg2.gif"); */
    background-repeat: repeat;
    height:100vh;
    overflow: hidden;
    overflow-y: auto;
    /* background-repeat: no-repeat; for gif5 */
}

@font-face {
    font-family: "Satisfy";
    src: url("./fonts/Satisfy-Regular.ttf") format("ttf"),
      url("./fonts/Satisfy-Regular.ttf") format("ttf");
    font-weight: bold;
    font-display: swap;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Satisfy";
    font-weight: 400;
  }

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Regular.ttf") format("ttf"),
      url("./fonts/Roboto-Regular.ttf") format("ttf");
    font-weight: bold;
    font-display: swap;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto";
    font-weight: 400;
  }