.MobileApp {
  text-align: center;
}
body {
  padding: 0%;
  margin: 0%;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

.mobile-createPostPage {
  width: 100%;
  height: calc(100vh - 80px);
  display: grid;
  place-items: center;
}

.mobile-cpContainer {
  width: 70%;
  height: auto;
  padding: 20px;
  background-color: transparent;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
}

.mobile-cpContainer h1 {
  text-align: center;
}

.mobile-cpContainer label {
  font-size: 25px;
}
.mobile-cpContainer .mobile-inputGp {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.mobile-inputGp input,
.mobile-inputGp textarea {
  font-size: 18px;
  border: none;
  border-radius: 2px;
  padding: 5px;
  white-space: pre-line;
}

.mobile-inputGp input {
  height: 40px;
}
.mobile-inputGp textarea {
  height: 150px;
}

.mobile-cpContainer button {
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}
input,
textarea {
  margin-top: 5px;
}

.loginPage {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginPage p {
  font-size: 30px;
}

/* GOOGLE BUTTOn */
.login-with-google-btn {
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 5px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 25px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.login-with-google-btn:active {
  background-color: #eeeeee;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}
.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.mobileHomePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
}

.mobileHomePage .mobilePost {
  width: 80%;
  max-width: 800px;
  height: auto;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.mobilePost .mobilePostHeader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mobilePostHeader .title {
  flex: 50%;
}
.mobilePostHeader .mobileDeletePost {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mobileDeletePost button {
  border: none;

  background: none;
  font-size: 30px;
  cursor: pointer;
}

.mobilePostHeader .mobilePostExternalLink {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.mobilePostExternalLink button {
  border: none;

  background: none;
  font-size: 30px;
  cursor: pointer;
}

.mobilePostTextContainer {
  word-wrap: break-word;
  height: auto;
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  text-align: justify;
  text-justify: inter-word;
}

.mobilePostTextContainer-readMore {
  display: block;
  width: fit-content; /* Adjusts button width to fit its content */
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  color: blue;
  background-color: transparent; /* Set transparent background */
  text-align: center; /* Center button text */
  margin: 20px auto; /* Center horizontally */
  border: 1px solid blue;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mobile-dedicatedPostTextContainer {
  word-wrap: break-word;
  height: auto;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  text-align: justify;
  text-justify: inter-word;
}

.mobile-image-wrapper {
  display: flex;
  justify-content: center;
}
.mobilePostImageContainer {
  width:auto;
  height: auto;
  max-height: 400px;
  position:relative;
  overflow: hidden;
  overflow-y: auto;
}
.mobile-player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
.mobilePostVideoContainer {
  position: absolute;
  top: 0;
  left: 0;
}
/* CSS for Logout button*/
.mobile-button-logout {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.mobile-button-logout:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.mobile-button-logout:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.mobile-button-logout:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.mobile-button-logout:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.mobile-button-logout:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.mobile-button-logout:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.mobile-button-logout:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.mobile-button-logout:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.mobilePostFooter{
  display:flex
}
.mobilePostFooter .author {
  flex: 50%;
  font-size:15px;
}
.mobilePostFooter .date {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size:15px;
}

.mobile-NavigationSpace{
  height: 100vgh;
  background-color: transparent;
}
.mobile-BlogHeading {
  font-family: "Satisfy";
  font-size: 2rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 1;
  top: 10px;
  left: 10px;
  color: #c6e2ff;
}

.mobile-privatePostOptionSelect{
  width: 100%;
  display: flex;
  justify-content: center;
}

.mobile-anonymousMessageDiv .mobile-anonymousTextboxAndSendDiv {
  height: 350px;
  margin-top: 20px;
  justify-content: center;
  padding: 5px;
  text-align: center;
}
.mobile-anonymousTextboxAndSendDiv .mobile-anonymousMessageTextbox {
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  font-size: 18px;
  height: 80%;
  padding: 20px;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

.mobile-anonymousTextboxAndSendDiv button {
  margin-top: 20px;
  height: 40px;
  justify-self: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.mobile-anonymousTextboxAndSendDiv .mobile-anonymousSendButton{
  background-image: url("../../public/sendSmallIcon.png");
  background-repeat:no-repeat;
  height:50%;
  justify-content: center;
  width:auto;
  min-height:50px;
  min-width:50px;
  border-color: transparent;
  background-color:transparent;
  cursor: pointer;
}
.mobile-anonymousSendButton:hover{
  background-color:rgb(219, 222, 222);
}

.mobile-anonymousSendButton:active{
  background-color:rgb(39, 105, 105);
}

.mobile-anonymousSendButton:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

/* dimension loading gif css start */
.mobile-LoadingContainer{
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color:transparent;
}
.mobile-LoadingContainer .mobile-LoadingText{
  font-family: "Satisfy";
  font-size: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 2rem;
  text-align: center;
  line-height: 1;
  color: #c6e2ff;
  animation: neon 0.08s ease-in-out infinite alternate;
}
.mobile-LoadingContainer .mobileLoadingImage{
  border-radius: 20px;
  height:50%;
}
/* dimension loading gif end */


/* Posts page */
.mobilePostsPage {
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.mobilePostsPage .mobile-anonymousMessageDiv {
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
  overflow: auto;
}

.mobilePostsPage .mobilePost {
  width: 80%;
  max-width: 800px;
  height: auto;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}
/* End posts page */