.App {
  text-align: center;
}
body {
  padding: 0%;
  margin: 0%;
  font-family: Arial, Helvetica, sans-serif;
}

nav {
  margin: 0;
  width: 100%;
  height: 80px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

.createPostPage {
  width: 100%;
  height: calc(100vh - 80px);
  display: grid;
  place-items: center;
}

.cpContainer {
  width: 500px;
  height: auto;
  padding: 20px;
  background-color: transparent;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
}

.cpContainer h1 {
  text-align: center;
}

.cpContainer label {
  font-size: 25px;
}

.cpContainer .inputGp {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.cpContainer .waitForSubmission {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.inputGp input,
.inputGp textarea {
  font-size: 18px;
  border: none;
  border-radius: 2px;
  padding: 5px;
}

.inputGp input {
  height: 40px;
}
.inputGp textarea {
  height: 150px;
}

.cpContainer button {
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}
input,
textarea {
  margin-top: 5px;
  white-space: pre-line
}

.loginPage {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginPage p {
  font-size: 30px;
}

/* GOOGLE BUTTOn */
.login-with-google-btn {
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 5px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 25px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.login-with-google-btn:active {
  background-color: #eeeeee;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}
.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.homePage {
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  /* background-size: 300% 300%;
  background-image: linear-gradient(
        -45deg, 
        rgba(59,173,227,1) 0%, 
        rgba(87,111,230,1) 25%, 
        rgba(152,68,183,1) 51%, 
        rgba(255,53,127,1) 100%
  );  
  animation: AnimateBG 20s ease infinite; */
}

.homePage .post {
  width: 50%;
  max-width: 800px;
  height: auto;
  max-height: 600px;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
  overflow: hidden;
  overflow-y: auto;
}

.homePage .dedicatedPost {
  width: 70%;
  max-height: auto;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.dedicatedPost .postHeader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.homePage .anonymousMessageDiv {
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
  overflow: auto;
}

.anonymousMessageDiv .anonymousTextboxAndSendDiv {
  height: 350px;
  margin-top: 20px;
  justify-content: center;
  padding: 5px;
  text-align: center;
}
.anonymousTextboxAndSendDiv .anonymousMessageTextbox {
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  font-size: 18px;
  height: 80%;
  padding: 20px;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

.anonymousTextboxAndSendDiv button {
  margin-top: 20px;
  height: 40px;
  justify-self: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.anonymousTextboxAndSendDiv .anonymousSendButton{
  background-image: url("../../public/sendSmallIcon.png");
  background-repeat:no-repeat;
  height:50%;
  justify-content: center;
  width:auto;
  min-height:50px;
  min-width:50px;
  border-color: transparent;
  background-color:transparent;
  cursor: pointer;
}
.anonymousSendButton:hover{
  background-color:rgb(219, 222, 222);
}

.anonymousSendButton:active{
  background-color:rgb(39, 105, 105);
}

.anonymousSendButton:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.dedicatedPost .postFooter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.post .postHeader {
  display: flex;
  justify-content: center;
  width: 100%;
}
.post .postFooter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.postHeader .title {
  flex: 50%;
}
.postHeader .postExternalLink {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.postExternalLink button {
  border: none;

  background: none;
  font-size: 30px;
  cursor: pointer;
}
.postFooter .author {
  flex: 50%;
}
.postFooter .deletePost {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.deletePost button {
  border: none;

  background: none;
  font-size: 30px;
  cursor: pointer;
}

.dedicatedPostTextContainer {
  word-wrap: break-word;
  height: auto;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  text-align: justify;
  text-justify: inter-word;
}

.postTextContainer {
  word-wrap: break-word;
  height: auto;
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  text-align: justify;
  text-justify: inter-word;
}

.image-wrapper {
  display: flex;
  justify-content: center;
}

.dedicatedPostImageContainer {
  width:auto;
  height: auto;
  max-height: 800px;
  position:relative;
  overflow: hidden;
  overflow-y: auto;
}

.postImageContainer {
  width:auto;
  height: auto;
  max-height: 400px;
  position:relative;
  overflow: hidden;
  overflow-y: auto;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
.postVideoContainer {
  position: absolute;
  top: 0;
  left: 0;
}

/* CSS for Logout button*/
.button-logout {
  position:fixed;
  right:10px;
  top:5px;
  align-items: normal;
  background-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  border-style: none;
  box-sizing: content-box;
  color: white; 
  cursor: pointer;
  display: inline;
  font-size: 20px;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: none;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
}

/* CSS google Logout button*/
.desktop-button-logout {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.desktop-button-logout:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.desktop-button-logout:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.desktop-button-logout:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.desktop-button-logout:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.desktop-button-logout:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.desktop-button-logout:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.desktop-button-logout:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.desktop-button-logout:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}


.postOptionSelect{
  width: 100%;
  display: flex;
  justify-content: center;
}
.postOptionSelect select {
  background-color: transparent;
  color: white;
  width: auto;
  font-size: 30px;
  border: none;
  border-radius: 2px;
  padding: 5px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  
  appearance: button;
  outline: none;
}

.postOptionSelect::before {
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.postOptionSelect:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}


.cpContainer .upload-box {
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  background-color: grey;
}

.upload-box input {
  cursor: pointer;
}

.LoadingContainer{
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color:transparent;
}
.LoadingContainer .LoadingText{
  font-family: "Satisfy";
  font-size: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 2rem;
  text-align: center;
  line-height: 1;
  color: #c6e2ff;
  animation: neon 0.08s ease-in-out infinite alternate;
}
.LoadingContainer .LoadingImage{
  border-radius: 20px;
  height:20%;
}


.privatePostOptionSelect{
  width: 100%;
  display: flex;
  justify-content: center;
}

/* This is the css for private post toggle button */

.checkbox-wrapper-2 .ikxBAC {
  display: flex;
  justify-content: center;
  appearance: none;
  background-color: #dfe1e4;
  margin-left: auto;
  margin-right:auto;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: #6e79d6;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #535db3;
}
/* End of the css for private post toggle button */
