/*-- Sign Styles --*/
.mobile-sign {
    max-width:100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mobile-sign__word {
    font-family: "Satisfy";
    font-size: 1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 3.6rem;
    text-align: center;
    line-height: 1;
    color: #c6e2ff;
}
/*-- Animation Keyframes --*/
@keyframes neon {
    from {
        text-shadow: 0 0 6px rgba(202, 228, 225, 0.92), 0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52), 0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78), 0 0 54px rgba(30, 132, 242, 0.92);
   }
    to {
        text-shadow: 0 0 6px rgba(202, 228, 225, 0.98), 0 0 30px rgba(202, 228, 225, 0.42), 0 0 12px rgba(30, 132, 242, 0.58), 0 0 22px rgba(30, 132, 242, 0.84), 0 0 38px rgba(30, 132, 242, 0.88), 0 0 60px rgba(30, 132, 242, 1);
   }
}
.mobile-link {
    width: 10%;
    font-family: "Satisfy";
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #828282;
    text-decoration: none;
}
.mobile-link:focus, .mobile-link:hover {
    color: #c6e2ff;
    text-shadow: 0 0 2px rgba(202, 228, 225, 0.92), 0 0 10px rgba(202, 228, 225, 0.34), 0 0 4px rgba(30, 132, 242, 0.52), 0 0 7px rgba(30, 132, 242, 0.92), 0 0 11px rgba(30, 132, 242, 0.78), 0 0 16px rgba(30, 132, 242, 0.92);
}
